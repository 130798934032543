.logo {
	display: block;
	size: 100px 50px;
	background-image: url('$images/blocks/logo/logo.xs.svg');
	background-size: contain;
	background-position: center;

	@media (--md) {
		size: 270px 50px;
		background-image: url('$images/blocks/logo/logo.md.svg');
	}
}
.car {

	@media (--md) {
		display: flex;
	}

	&__left {

		@media (--md) {
			width: 40%;
			padding-right: 30px;
		}

		@media (--lg) {
			width: 30%;
		}
	}

	&__right {

		@media (--md) {
			width: 60%;
		}

		@media (--lg) {
			width: 70%;
		}
	}

	&__cat {
		font-size: 16px;
		color: $color-4;

		@media (--sm) {
			font-size: 17px;
		}

		@media (--md) {
			font-size: 18px;
		}
	}

	&__visual {}

	&__img {}

	&__table {
		width: 100%;
		font-weight: 300;

		thead {
			border-bottom: 1px dashed $color-4;
		}

		tbody {

			tr {

				&:first-child,
				&:last-child {

					 td {
						 padding-top: 15px;
					 }
				}
			}
		}

		th,
		td {

			&:first-child {
				width: 30%;
			}
		}

		th {
			font-weight: 500;
			text-align: left;
			padding-bottom: 5px;

			&:nth-child(2) {
				color: $color-4;
			}
		}

		td {
			padding: 3px *;

			&:first-child {
				padding-right: 20px;
			}
		}
	}

	&__capacity {
		display: flex;
		align-items: center;
		list-style-type: none;
	}

	&__capacity-item {
		position: relative;
		size: 20px;

		&:not(:last-child) {
			margin-right: 30px;
		}
		
		&:before {
			content: '';
			display: block;
			background-position: center;
			background-size: contain;
		}
	}

	&__capacity-item-number {
		border-radius: 50%;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
		min-width: 20px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		font-weight: bold;
		color: $color-4;
		position: absolute;
		right: -18px;
		bottom: -5px;
		/* z-index: 1; */
		background-color: #fff;
	}

	&__capacity-item_person {
		
		&:before {
			@extend .icon-man;
		}
	}

	&__capacity-item_suitcase {
		
		&:before {
			@extend .icon-case;
		}
	}

	&__capacity-item_bag {
		
		&:before {
			@extend .icon-backpack;
		}
	}
}
.location {

	&__text {

		@media (--xl-less) {
			margin-bottom: 20px;
		}

		table {

			td {

				&:first-child {
					padding-right: 20px;
				}
			}
		}
	}

	&__map {
		flex-grow: 1;
		height: 300px;
	}
}
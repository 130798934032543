.apps {
	background-color: #111119;

	&__inner {
		position: relative;
		padding: 50px * 25px;

		&:after {
			content: '';
			height: 1px;
			background-color: rgba(244,244,244,0.5);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	&__heading {
		text-align: center;

		&.heading-2 {
			margin-bottom: 50px;
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: -20px 10px *;
	}

	&__button {
		margin: 20px 10px *;
	}

	&__button-img {
		display: block;
	}
}
.heading {
	font-size: 24px;
	font-family: $font-family-2;
	font-weight: 300;
	margin-bottom: 20px;

	@media (--sm) {
		font-size: 26px;
	}

	@media (--md) {
		font-size: 28px;
	}

	@media (--lg) {
		font-size: 30px;
	}

	@media (--xl) {
		font-size: 32px;
	}
}

[lang="am"] {
	.heading {
		font-family: $font-family-1-am;
	}
}
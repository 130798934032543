.form-label {
	display: flex;
	align-items: center;
	min-height: 34px;
	padding: 5px *;

	@media (--lg) {
		justify-content: flex-end;
	}

	&_error {
		color: red;
	}
}
.page-content {
	text-align: justify;

	h1, h2, h3, h4, h5, h6 {
		@extend .heading;
	}

	h2 {
		font-size: 18px;

		@media (--sm) {
			font-size: 19px;
		}

		@media (--md) {
			font-size: 20px;
		}

		@media (--lg) {
			font-size: 21px;
		}

		@media (--xl) {
			font-size: 22px;
		}
	}

	ul, ol {
		@extend .list;
	}

	table {
		@extend .table;
		margin-bottom: 20px;
	}
}

[lang="am"] {
	.page-content {

		h1, h2, h3, h4, h5, h6 {
			font-family: $font-family-1-am;
		}
	}
}
.slider-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	size: 40px;
	background-color: rgba(0, 0, 0, .55);
	transition: all .2s ease-in-out;

	&:before {
		content: '';
		flex-shrink: 0;
		size: 16px;
		background-position: center;
		background-size: contain;
	}

	&:not(.swiper-button-disabled) {
		cursor: pointer;
		
		&:hover {
			background-color: $color-4;
		}
	}

	&.swiper-button-disabled {
		opacity: .5;
	}

	&_prev {

		&:before {
			background-image: url('$images/icons/angle-arrow-left.svg', true);
		}
	}

	&_next {

		&:before {
			background-image: url('$images/icons/angle-arrow-right.svg', true);
		}
	}
}
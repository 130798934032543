.form-control {
	display: block;
	width: 100%;
	outline: none;
	height: 34px;
	padding: 6px 12px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 0;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	font-size: 14px;
	color: #555;
	transition: all .2s ease-in-out;

	&:focus {
		border-color: $color-4;
	}

	&_error {
		border-color: red;
	}
}
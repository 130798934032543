.nav {
	border-width: 1px 0;
	border-style: solid;
	border-color: $color-5;
	background-color: #fff;

	@media (--sm) {
	    border-width: 1px;
		width: 400px;
	}

	&__list {
		list-style-type: none;
	}

	&__item {}

	&__link {
		display: block;
		color: $color-5;
		text-transform: uppercase;
		text-decoration: none;
		padding: 10px;

		&_active {
			background-color: $color-4;
		}
		
		&:hover {
			color: $color-4;
			background-color: #e6e6e6;
		}
	}
}
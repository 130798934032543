.heading-2 {
	font-size: 32px;
	font-family: $font-family-2;
	font-weight: 300;
	color: $color-4;
	margin-bottom: 20px;

	@media (--sm) {
		font-size: 34px;
	}

	@media (--md) {
		font-size: 36px;
	}

	@media (--lg) {
		font-size: 38px;
	}

	@media (--xl) {
		font-size: 40px;
	}
}

[lang="am"] {
	.heading-2 {
		font-family: $font-family-1-am;
	}
}
.header {
	background-color: $color-5;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		padding: 15px *;
	}

	&__lang {
		margin-left: auto;
	}

	&__nav-btn {
		margin-left: 20px;
	}

	&__nav {
		display: none;
		position: absolute;
		top: 90%;
		left: -$container-padding;
		right: -$container-padding;
		z-index: 10;

		@media (--sm) {
			left: auto;
			right: 0;
		}
	}

	&__steps {
		margin-left: auto;
	}

	&__currency {
		margin-left: 20px;
	}
}
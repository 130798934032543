.product {

	&__visual {
		display: flex;
		justify-content: center;
		align-items: center;

		@media (--md-less) {
			margin-bottom: 10px;
		}
	}

	&__img {
		display: block;
	}

	&__info {

		@media (--md-less) {
			text-align: center;
			margin-bottom: 30px;
		}
	}

	&__name {
		font-size: 20px;
		line-height: 1.25;
		margin-bottom: 5px;
	}

	&__text {
		font-size: 14px;
		color: #999;
		margin-bottom: 10px;
	}

	&__text-2 {
		font-size: 15px;
		font-weight: 600;
		color: $color-4;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	&__details {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		margin: -5px -10px 30px;

		@media (--md-less) {
			justify-content: center;
		}
	}

	&__detail {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin: 5px 10px *;

		&:before {
			content: '';
			display: block;
			background-position: center;
			background-size: contain;
			margin-right: 5px;
		}

		&_1:before {
			@extend .icon-snowflake;
		}

		&_2:before {
			@extend .icon-gearbox-auto;
		}

		&_3:before {
			@extend .icon-gearbox-manual;
		}

		&_4:before {
			@extend .icon-man;
		}

		&_5:before {
			@extend .icon-car-door;
		}

		&_6:before {
			@extend .icon-case;
		}
	}

	&__features {}

	&__features-title {
		font-size: 14px;
		margin-bottom: 10px;
	}

	&__features-list {

		&.list-2 {

			@media (--md-less) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&__choose {
		border: 1px solid #ccc;
		padding: 10px 25px 10px 15px;
	}

	&__price {
		margin-bottom: 2px;
	}

	&__price-number {
		font-size: 24px;
	}

	&__price-currency {
		font-size: 16px;
	}

	&__price-time {
		font-size: 12px;
	}

	&__total {
		font-size: 14px;
		text-transform: uppercase;
		color: #969696;
	}

	&__btn {
		width: 100%;

		@media (--lg-only) {
			margin-top: 10px;
		}
	}
}
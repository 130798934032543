.teaser {
	background-color: #ebebeb;

	&__inner {
		padding-top: 20px;
		padding-bottom: 20px;

		@media (--sm) {
			padding-bottom: 25px;
		}

		@media (--md) {
			padding-bottom: 30px;
		}

		@media (--lg) {
			padding-bottom: 40px;
		}

		@media (--xl) {
			padding-bottom: 50px;
		}
	}

	&__heading {
		text-align: center;
	}

	&__items {}

	&__item-wrapper {

		@media (--lg-less) {
			margin-bottom: 30px;
		}
	}

	&__item {
		position: relative;
		background-color: #fff;
	}

	&__item-box {
		max-width: 300px;
	}

	&__item-link {
		display: block;
		padding-top: 60%;
	}

	&__item-icon-box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&__item-icon {
		max-width: 100%;
		max-height: 100%;
	}

	&__item-text {
		font-size: 18px;
		font-weight: 600;
		color: #000;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		left: 50%;
		padding-right: 10px;
		transform: translateY(-50%);
	}
}
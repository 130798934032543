.steps {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style-type: none;
	counter-reset: step;

	&__item {
		counter-increment: step;

		&:not(:last-child) {
			margin-right: 10px;

			@media (--md) {
				margin-right: 20px;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		color: $color-3;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;

		&:before {
			content: counter(step);
			display: flex;
			justify-content: center;
			align-items: center;
			size: 30px;
			border-radius: 50%;
			background-color: #999;
			color: #fff;
			font-size: 16px;
			font-weight: 700;

			@media (--lg) {
				margin-right: 10px;
			}
		}

		&_active {
			color: #fff;

			&:before {
				background-color: $color-4;
			}
		}

		&:hover {
			text-decoration: none;
			color: #fff;
		}
	}

	&__link-text {

		@media (--lg-less) {
			display: none;
		}
	}
}
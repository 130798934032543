.p-special-offers {

	@media (--lg) {
		padding: * 30px;
	}

	&__heading {

		&.heading-2 {
			margin-bottom: 50px;
		}
	}
}
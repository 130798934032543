.footer {
	color: #fff;
	background-color: $color-5;
	margin-top: auto;

	&__inner {
		padding: 15px *;

		@media (--md) {
			padding: 30px *;
		}
	}

	&__nav {}

	&__nav-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style-type: none;
		margin: * -5px -5px;

		@media (--md) {
			justify-content: flex-start;
		}
	}

	&__nav-item {
		margin: * 5px 5px;
	}

	&__nav-link {
		font-size: 12px;
		font-weight: 300;
		color: $color-2;
		text-decoration: none;
		transition: all .2s ease-in-out;

		&:hover {
			color: #fff;
			text-shadow: 0 0 1px #fff;
		}
	}
}
.page {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background-color: #ebebeb;

	&__inner {
		flex-grow: 1;
		background-color: #fff;
		padding: 20px *;

		@media (--sm) {
			padding: 25px *;
		}

		@media (--md) {
			padding: 30px *;
		}

		@media (--lg) {
			padding: 40px *;
		}

		@media (--xl) {
			padding: 50px *;
		}
	}

	&__subnav {
		margin-left: -15px;

		@media (--md-less) {
			margin-right: -15px;
		}

		@media (--md-less) {
			margin-bottom: 20px;
		}

		@media (--md) {
			margin-left: -25px;
		}
	}
}

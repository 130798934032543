.info {
	background-color: $color-6;
	color: $color-2;

	&__inner {
		padding: 20px *;

		@media (--sm) {
			padding: 25px *;
		}

		@media (--md) {
			padding: 30px *;
		}
	}

	&__tabs {
		margin-bottom: 30px;
	}

	&__tab {
		font-size: 15px;
		text-align: center;
		text-transform: uppercase;
		padding: 14px 15px;
		background-color: $color-7;
		border-bottom: 1px solid $color-7;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;

		&_active {
			color: $color-4;
			border-color: $color-4;
			cursor: default;
		}

		&:hover {
			color: $color-4;
		}
	}

	&__content {
		font-size: 12px;
		line-height: 1.5;
		text-align: justify;

		&:not(.info__content_active) {
			display: none;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-size: 18px;
		line-height: 1.25;
		text-transform: uppercase;
		text-align: center;
		margin: 20px *;

		@media (--sm) {
			font-size: 19px;
			margin: 30px *;
		}

		@media (--md) {
			font-size: 20px;
			margin: 40px *;
		}
	}

	&__flags {
		margin-bottom: 20px;
	}

	&__flags-title {
		font-size: 16px;
		font-weight: 200;
		line-height: 1.25;
		text-transform: uppercase;

		@media (--md-less) {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	&__flags-items {
		display: flex;
		flex-wrap: wrap;
		margin: -4px -8px;

		@media (--md-less) {
			justify-content: center;
		}
	}

	&__flags-item {
		margin: 4px 8px;
	}

	hr {
		border-color: rgba(244,244,244,.5);
	}

	ul {
		list-style-type: none;
		margin-bottom: 20px;

		a {
			color: inherit;
		}
	}

	li {
		margin-bottom: 5px;
	}
}
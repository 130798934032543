.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: #fff;
	border: none;
	background-color: $color-4;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	padding: 9px 20px;
	cursor: pointer;
	transition: all .2s ease-in-out;
	user-select: none;

	&:hover {
		background-color: color($color-4 whiteness(30%));
	}

	&_success {

		&:before {
			content: '';
			display: block;
			size: 15px;
			background-image: url('$images/icons/right-chevron.svg');
			background-position: center;
			background-size: contain;
			margin-right: 5px;
			position: relative;
			top: -1px;
		}
	}
}
.p-reservation {

	&__heading {
		font-size: 32px;
		/* font-family: $font-family-1; */
		font-weight: 200;
		color: $color-4;
		margin-bottom: 20px;

		@media (--sm) {
			font-size: 34px;
			margin-bottom: 25px;
		}

		@media (--md) {
			font-size: 36px;
			margin-bottom: 30px;
		}

		@media (--lg) {
			font-size: 38px;
		}

		@media (--xl) {
			font-size: 40px;
		}
	}

	&__title {
		font-family: $font-family-2;
		font-size: 18px;
		font-weight: 500;
		color: #666;
		margin-bottom: 15px;

		@media (--sm) {
			font-size: 19px;
		}

		@media (--md) {
			font-size: 20px;
		}
	}

	&__title-2 {
		font-size: 20px;
		font-weight: 200;
		color: $color-4;

		@media (--sm) {
			font-size: 21px;
		}

		@media (--md) {
			font-size: 22px;
		}
	}

	&__text-1 {
		font-weight: 500;
		margin-bottom: 15px;
	}

	/* ------------------------------------------------- */

	&__info {
		display: flex;
		flex-direction: column;
		color: $color-3;

		@media (--md-less) {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	&__pic {}

	&__img {
		display: table;
		margin: auto;
	}

	&__name {
		margin-bottom: 5px;
	}

	&__definition-list {
		line-height: 1.42;
		margin-top: 20px;
	}

	&__info-more-btn {
		align-self: center;
		padding: 10px;
		margin-top: 10px;
	}

	&__definition-title {
		font-weight: 700;
		margin-bottom: 5px;
	}

	&__definition-desc {

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	&__details {

		@media (--md-less) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__detail {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&:before {
			content: '';
			display: block;
			background-position: center;
			background-size: contain;
			margin-right: 5px;
		}

		&_1:before {
			@extend .icon-snowflake;
		}

		&_2:before {
			@extend .icon-gearbox-auto;
		}

		&_3:before {
			@extend .icon-gearbox-manual;
		}

		&_4:before {
			@extend .icon-man;
		}

		&_5:before {
			@extend .icon-car-door;
		}

		&_6:before {
			@extend .icon-case;
		}

		&_7:before {
			@extend .icon-backpack;
		}

		&_8:before {
			@extend .icon-id-card;
		}
	}

	/* ------------------------------------------------- */

	&__price {
		display: flex;
		justify-content: space-between;
	}

	&__price-name {}

	&__price-value {
		white-space: nowrap;
		margin-left: 20px;
	}

	/* ------------------------------------------------- */

	&__features {
		margin-bottom: 20px;
	}

	/* ------------------------------------------------- */

	&__options {}

	&__option {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		user-select: none;
		padding: 20px 10px;
		background-color: $color-2;
		margin-bottom: 20px;

		@media (--xs-only) {
			flex-direction: column;
		}
	}

	&__option-info {
		display: flex;

		@media (--xs-only) {
			margin-bottom: 10px;
		}
	}

	&__option-price {
		white-space: nowrap;

		@media (--xs-only) {
			font-weight: 600;
			text-align: right;
		}

		@media (--sm) {
			margin-left: 15px;
		}
	}

	&__option-select {
		margin-right: 10px;
	}

	&__options-more-btn {
		display: table;
		margin: auto;
		padding: 10px;
		margin-bottom: 20px;
	}

	/* ------------------------------------------------- */

	&__total {
		display: flex;
		flex-direction: column;
	}

	&__total-price {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 600;
		margin-bottom: 5px;
	}

	&__total-price-name {
		font-size: 21px;
	}

	&__total-price-value {
		font-size: 22px;
	}

	&__total-text-1 {
		font-weight: 500;
		margin-bottom: 5px;
	}

	&__total-text-2 {
		font-size: 12px;
		margin-bottom: 20px;
	}

	&__total-text-3 {
		font-size: 18px;
		color: $color-4;
		text-align: center;
		margin-bottom: 20px;
	}

	&__total-submit {
		align-self: center;
	}

	/* ------------------------------------------------- */

	&__driver-data {
		margin-bottom: 30px;

		.p-reservation__title-2 {
			margin-bottom: 10px;
		}
	}

	&__driver-data-phone {

		@media (--lg-less) {
			margin-top: 15px;
		}
	}

	&__alert {
		margin-bottom: 15px;
	}

	/* ------------------------------------------------- */

	&__bottom {
		margin-top: 30px;

		@media (--sm) {
			margin-top: 40px;
		}

		@media (--md) {
			margin-top: 50px;
		}

		@media (--lg) {
			margin-top: 60px;
		}

		@media (--xl) {
			margin-top: 70px;
		}
	}
}

[lang="am"] {
	.p-reservation {

		&__title {
			font-family: $font-family-1-am;
		}
	}
}
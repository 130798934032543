.list-2 {
	list-style-type: none;

	li {
		position: relative;
		padding-left: 25px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 1px;
			left: 0;
			size: 15px;
			background-image: url('$images/icons/check.svg', true);
			background-size: contain;
			background-position: center;
		}
	}
}
.more-content {
	display: none !important;

	&_active {
		display: block !important;
	}

	&_md-less {

		@media (--md) {
			display: block !important;
		}
	}
}
.currency {

	&__btn {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 600;
		color: #fff;
		background-color: #666;
		padding: 10px 14px;
		cursor: pointer;
		user-select: none;
		
		&:after {
			content: '';
			display: block;
			size: 15px 20px;
			background-image: url('$images/icons/angle-arrow-down.svg', true);
			background-position: center;
			background-size: contain;
			margin-left: 10px;
			transition: all .2s ease-in-out;
		}

		&_active {

			&:after {
				transform: rotate(180deg);
			}
		}
	}

	&__box {
		display: none;
		min-width: 300px;
		position: absolute;
		top: 100%;
		left: -$container-padding;
		right: -$container-padding;
		background-color: #fff;
		padding: 20px;
		z-index: 10;

		@media (--sm) {
			left: auto;
			right: 0;
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
		}
	}

	&__heading {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	&__text {}

	&__form {
		display: block;
		margin-top: 30px;
	}

	&__label {
		display: inline-block;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	&__select {
		margin-bottom: 10px;
	}

	&__submit {
		width: 100%;
	}
}
.booking {
	position: relative;

	&__inner {
		padding: 30px *;

		@media (--lg) {
			position: relative;
			padding-right: 155px;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
	}
	
	&__title {
		font-size: 13px;
		line-height: 1.25;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	&__control {
		position: relative;
		display: flex;
		height: 55px;
		font-size: 13px;
		color: $color-1;
		background-color: #ebebeb;
		cursor: pointer;
		margin-top: auto;
	}

	&__input {
		width: 100%;
		height: 100%;
		font-size: inherit;
		border: 0;
		outline: 0;
		background: transparent;
		position: relative;
		text-overflow: ellipsis;
		padding-right: 5px;
	}

	&__station {
		display: flex;
		align-items: center;
		padding-left: 35px;
	}

	&__station-box {
		position: relative;
	}

	&__station-start-box {
		display: flex;
		flex-direction: column;

		.booking__stations-info {
			left: 100%;
			margin-left: 5px;
		}
	}

	&__station-end-box {

		.booking__title {
			position: relative;
			padding-right: 30px;
		}

		.booking__stations-info {
			right: 100%;
			margin-right: 5px;
		}
	}

	&__stations {
		display: none;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: 5px;
		z-index: 10;
	}

	&__stations-content {
		max-height: 500px;
		overflow: auto;
	}

	&__stations-title {
		padding: 6px 12px;
		text-transform: uppercase;
		color: $color-4;
		font-size: 14px;
		font-weight: 600;
		user-select: none;
	}

	&__stations-list {
		list-style-type: none;
	}

	&__stations-item {
		position: relative;
		padding: 10px 10px 10px 40px;
		font-size: 14px;
		color: $color-1;
		line-height: 1.25;
		cursor: pointer;
		user-select: none;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			background-position: center;
			background-size: contain;
		}
		
		&:hover,
		&_hover {
			background-color: #f5f5f5;
		}

		&_airport:before {
			size: 20px;
			background-image: url('$images/icons/airplane.svg', true);
		}

		&_city:before {
			size: 15px;
			background-image: url('$images/icons/building.svg', true);
		}

		&_train:before {
			size: 15px;
			background-image: url('$images/icons/train.svg', true);
		}
	}

	&__stations-info {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		position: absolute;
		top: -1px;
	}

	&__stations-info-item {
		text-align: center;
		line-height: 1.25;
	}

	&__station-details {
		padding: 10px;

		table {

			td {

				&:first-child {
					padding-right: 20px;
				}
			}
		}
	}

	&__station-details-title {
		font-weight: 600;
		margin-bottom: 10px;
	}

	&__station-details-info {
		margin-bottom: 10px;
	}

	&__station-map {
		size: 400px 270px;
		background-color: blue;
	}

	&__date,
	&__time {

		.booking__input {
			padding-left: 35px;
		}
	}

	&__date {
		width: 55%;
		height: 100%;

		.prev,
		.next {
			padding: 5px *;
		}
		
		.day {
			padding: 2px 5px;
			border-radius: 0;

			&:hover {
				color: #fff;
				background-color: #ff7d19 !important;
			}
		}

		.active {
			background-color: #ff7d19 !important;
		}

		.today {
			border: 2px solid #ff7d19;

			&:before {
				border-bottom-color: #ff7d19 !important;
				bottom: 2px !important;
				right: 2px !important;
			}
		}
	}

	&__time {
		width: 45%;
		height: 100%;

		.btn {
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}
	}

	&__icon-up,
	&__icon-down,
	&__icon-prev,
	&__icon-next {
		display: flex !important;
		justify-content: center;
		align-items: center;

		&:before {
			content: '';
			background-size: contain;
		}
	}

	&__icon-up,
	&__icon-down {

		&:before {
			size: 20px;
		}
	}

	&__icon-prev,
	&__icon-next {

		&:before {
			size: 15px;
		}
	}

	&__icon-up:before {
		background-image: url('$images/icons/angle-arrow-up.2.svg', true) !important;
	}

	&__icon-down:before {
		background-image: url('$images/icons/angle-arrow-down.2.svg', true) !important;
	}

	&__icon-prev:before {
		background-image: url('$images/icons/left-chevron.2.svg', true) !important;
	}

	&__icon-next:before {
		background-image: url('$images/icons/right-chevron.2.svg', true) !important;
	}

	&__submit {
		margin-top: auto;

		@media (--lg) {
			position: absolute;
			right: 0;
			bottom: 45px;
		}

		&.button {
			width: 100%;
			height: 55px;

			@media (--lg) {
				size: 125px;
			}
		}
	}

	&__submit-text {

		@media (--lg) {
			display: none;
		}
	}

	&__submit-icon {
		display: block;
		size: 40px;
		flex-shrink: 0;
		background-image: url('$images/icons/angle-arrow-right.svg', true);
		background-position: center;
		background-size: contain;

		@media (--lg-less) {
			display: none;
		}
	}

	&__search-info {
		padding: 10px;
	}

	&__get-countries {
		color: #3979C3;
		text-decoration: underline;
		cursor: pointer;
	}

	&__countries-title,
	&__cities-title {
		padding: 6px 12px;
		color: $color-4;
		font-size: 14px;
		font-weight: 600;
		user-select: none;
	}

	&__countries-items,
	&__cities-items {
		list-style-type: none;
	}

	&__countries-item,
	&__cities-item {
		padding: 10px;
		font-size: 14px;
		color: $color-1;
		line-height: 1.25;
		cursor: pointer;
		user-select: none;

		&:hover {
			background-color: #f5f5f5;
		}
	}



	&_icon {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-size: contain;
			background-position: center;
		}
	}

	&_placemark {

		&:before {
			size: 26px;
			left: 5px;
			background-image: url('$images/icons/placemark.svg');
		}
	}

	&_calendar {

		&:before {
			size: 20px;
			left: 10px;
			background-image: url('$images/icons/calendar-2.svg');
		}
	}

	&_clock {

		&:before {
			size: 20px;
			left: 10px;
			background-image: url('$images/icons/clock.svg');
		}
	}

	&_theme_2 {

		.container {
			background-color: #fff;
			box-shadow: 0 9px 18px 0 rgba(0, 0, 0, .1);
		}
	}
}
.lang {
	position: relative;
	z-index: 1;
	text-align: center;

	&__btn {
		font-size: 18px;
		color: #fff;
		text-transform: uppercase;
		cursor: pointer;
		user-select: none;
		transition: all .2s ease-in-out;

		&:hover {
			color: $color-4;
		}
	}

	&__list {
		display: none;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		list-style-type: none;
	}

	&__item {
		margin-top: 5px;
	}

	&__link {
		display: block;
		font-size: 18px;
		color: #fff;
		background-color: #111119;
		padding: 5px;
		text-transform: uppercase;
		cursor: pointer;
		user-select: none;
		transition: all .2s ease-in-out;

		&:hover {
			color: $color-4;
		}
	}
}
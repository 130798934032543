.checkbox {
	display: inline-flex;
	vertical-align: top;
	user-select: none;
	line-height: 1.25;
	cursor: pointer;

	&:before {
		content: '';
		display: inline-block;
		size: 16px;
		border: 1px solid #a5a5a5;
		position: relative;
		margin-right: 10px;
		flex-shrink: 0;
	}

	input& {
		display: none;

		&:checked + .checkbox:before {
			border-color: $color-4;
			background-image: url('$images/icons/check.svg', true);
			background-position: center;
			background-size: 80%;
		}
	}

	&_big {
		padding-top: 2px;

		&:before {
			size: 22px;
			top: -2px;
		}
	}
}
.more-btn {
	font-size: 14px;
	line-height: 1;
	cursor: pointer;
	user-select: none;
	text-decoration: none;
	color: #666;
	transition: all .2s ease-in-out;

	&:hover {
		color: #404040;
		text-decoration: underline;
	}

	&__hide {
		display: none;
	}

	&_active {

		.more-btn__show {
			display: none;
		}

		.more-btn__hide {
			display: inline;
		}
	}

	&_theme_2 {
		text-transform: uppercase;
	}

	&_md-less {

		@media (--md) {
			display: none;
		}
	}
}
.list {
	padding-left: 25px;
	margin-bottom: 20px;

	@media (--sm) {
		padding-left: 30px;
	}

	@media (--md) {
		padding-left: 40px;
	}
}
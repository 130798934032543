.partners {
	background-color: #fff;

	&__inner {
		padding: 20px *;

		@media (--sm) {
			padding: 25px *;
		}

		@media (--md) {
			padding: 30px *;
		}

		@media (--lg) {
			padding: 40px *;
		}

		@media (--xl) {
			padding: 50px *;
		}
	}

	&__heading {
		text-align: center;
	}

	&__items {
		margin-top: -10px;
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
	}
}
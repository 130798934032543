.benefits {
	background-color: #fff;

	&__inner {
		padding: 20px *;

		@media (--sm) {
			padding: 25px *;
		}

		@media (--md) {
			padding: 30px *;
		}

		@media (--lg) {
			padding: 40px *;
		}

		@media (--xl) {
			padding: 50px *;
		}
	}

	p {
		font-size: 20px;
		font-weight: 200;
		line-height: 1.1;
		text-align: center;

		@media (--sm) {
			font-size: 21px;
		}

		@media (--md) {
			font-size: 22px;
		}

		@media (--lg) {
			font-size: 23px;
		}

		@media (--xl) {
			font-size: 24px;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}
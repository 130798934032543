.bookingmask {
	color: #999;
	cursor: pointer;
	user-select: none;

	.container {
		background-color: #F8F8F8;
		padding: 20px *;
	}
	
	&__item {
		position: relative;
		padding-left: 30px;

		@media (--md-less) {
			display: table;
			margin: * auto 20px;
		}
		
		&:before {
			content: '';
			display: block;
			width: 20px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-position: center;
			background-size: contain;
		}
		
		&_1,
		&_2 {
			
			&:before {
				background-image: url('$images/icons/right-arrow-forward.svg');
			}
		}

		&_3 {

			&:before {
				background-image: url('$images/icons/calendar.svg');
			}
		}
	}

	&__text-1 {
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
	}

	&__text-2 {
		font-size: 12px;
		font-weight: 300;
	}

	&__edit {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		color: $color-4;
		padding-right: 20px;

		@media (--md) {
			justify-content: flex-end;
		}
		
		&:after {
			content: '';
			position: relative;
			top: -1px;
			display: block;
			size: 15px 20px;
			background-image: url('$images/icons/angle-arrow-down.2.svg', true);
			background-position: center;
			background-size: contain;
			margin-left: 10px;
			transition: all .2s ease-in-out;
		}
	}

	&_active {

		.bookingmask__edit {

			&:after {
				transform: rotate(180deg);
			}
		}
	}
}
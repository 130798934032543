.dropdown {
	position: relative;
	z-index: 2;

	&__btn {
		position: relative;
		font-size: 14px;
		font-weight: 700;
		color: $color-1;
		letter-spacing: .3px;
		border: 2px solid #c0c0c0;
		padding: 10px 35px 10px 10px;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;
		user-select: none;

		&:after {
			content: '';
			display: block;
			size: 16px;
			margin-top: -8px;
			position: absolute;
			right: 10px;
			top: 50%;
			background-image: url('$images/icons/angle-arrow-down.3.svg', true);
			background-position: center;
			background-size: contain;
			transition: all .2s ease-in-out;
		}

		&_active {

			&:after {
				transform: rotate(180deg);
			}
		}
	}

	&__box {
		display: none;
		position: absolute;
		top: 105%;
		left: 0;
		right: 0;
		border: 2px solid #c0c0c0;
		padding: 10px;
		background-color: #fff;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .12);
	}

	&__items {}

	&__item {}

	input.checkbox:checked + .checkbox {
		color: $color-4;
	}

	&__checkbox {
		width: 100%;
		margin: * -10px;
		padding: 10px;
		font-size: 14px;
		text-transform: uppercase;
		color: #000;
		letter-spacing: .3px;

		&:hover {
			color: $color-4;
		}
	}

	&__submit {
		width: 100%;
		margin-top: 10px;
	}
}
.nav-btn {
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	&__text {
		font-size: 18px;
		color: #fff;
		text-transform: uppercase;
	}

	&__icon {
		position: relative;
		top: -1px;
		width: 30px;
		height: 20px;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		margin-left: 10px;

		span {
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: #fff;
			/*border-radius: 9px;*/
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 8px;
			}

			&:nth-child(4) {
				top: 16px;
			}
		}
	}

	&_active {

		.nav-btn__icon {

			span {

				&:nth-child(1) {
					top: 18px;
					width: 0;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}

				&:nth-child(4) {
					top: 18px;
					width: 0;
					left: 50%;
				}
			}
		}
	}
}
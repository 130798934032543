.products {

	&__filters {
		margin-bottom: 20px;
	}

	&__filter {

		@media (--lg-less) {
			margin-bottom: 10px;
		}
	}

	&__item {
		padding: 20px *;
		border-bottom: 1px solid #ccc;

		@media (--sm) {
			padding: 25px *;
		}

		@media (--md) {
			padding: 30px *;
		}

		@media (--lg) {
			padding: 35px *;
		}

		@media (--xl) {
			padding: 40px *;
		}
	}
}
.subnav {
	position: relative;
	background-color: #e6e6e6;
	padding: 15px;

	@media (--md) {
		padding: 25px 15px 25px 50px;
	}

	&:after {

		@media (--md) {
			content: '';
			position: absolute;
			left: 0;
			top: 100%;
			size: 0;
			border-top: 7px solid #070908;
			border-left: 10px solid transparent;
		}
	}

	&__list {
		list-style-type: none;
	}

	&__item {}

	&__link {
		position: relative;
		display: inline-block;
		font-size: 16px;
		text-decoration: none;
		color: #666;
		padding: 10px * 10px 15px;
		transition: all .2s ease-in-out;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			size: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid #666;
			transition: inherit;
		}

		&:hover {
			color: $color-4;

			&:before {
				border-left-color: $color-4;
			}
		}
	}
}
.hero-slider {
	position: relative;

	img {
		display: block;
		width: 100%;
	}

	.swiper-slide {
		height: 300px;
		background-position: center;
		background-size: cover;

		@media (--sm) {
			height: 350px;
		}

		@media (--md) {
			height: 400px;
		}

		@media (--lg) {
			height: 450px;
		}

		@media (--xl) {
			height: 500px;
		}
	}

	&__slider-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}

	&__slider-btn_prev {
		left: 20px;
	}

	&__slider-btn_next {
		right: 20px;
	}
}